import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Card, Pagination } from "antd";

interface BlogMetadata {
  id: number;
  slug: string;
  title: string;
  date: string;
  image: string;
  excerpt: string;
}

const BlogList: React.FC = () => {
  const [blogs, setBlogs] = useState<BlogMetadata[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 6;

  useEffect(() => {
    fetch("/blogs.json")
      .then((response) => response.json())
      .then((data: BlogMetadata[]) => {
        // Sort blogs from latest to oldest
        const sortedBlogs = data.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());
        setBlogs(sortedBlogs);
      })
      .catch((error) => console.error("Error fetching blogs:", error));
  }, []);

  // Paginate blogs
  const paginatedBlogs = blogs.slice((currentPage - 1) * pageSize, currentPage * pageSize);

  return (
    <div className="max-w-6xl mx-auto p-6">
      <h1 className="text-3xl font-bold text-center mb-6">Blog</h1>

      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
        {paginatedBlogs.map((blog) => (
          <Card
            key={blog.id}
            hoverable
            className="rounded-lg shadow-lg"
            cover={
              <img
                src={blog.image}
                alt={blog.title}
                className="h-48 w-full object-cover rounded-t-lg"
              />
            }
          >
            <h2 className="text-xl font-semibold mb-1">{blog.title}</h2>
            <p className="text-gray-500 text-sm mb-2">Published on {blog.date}</p>
            <p className="text-gray-600">{blog.excerpt}</p>
            <div className="mt-4 text-center">
              <Link
                to={`/blog/${blog.slug}`}
                className="inline-block bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-700 transition"
              >
                Read More
              </Link>
            </div>
          </Card>
        ))}
      </div>

      {/* Pagination */}
      <div className="flex justify-center mt-8">
        <Pagination
          current={currentPage}
          pageSize={pageSize}
          total={blogs.length}
          onChange={(page) => setCurrentPage(page)}
        />
      </div>
    </div>
  );
};

export default BlogList;
